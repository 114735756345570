import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';

const Wrapper = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.section`
  text-align: center;
  color: ${props => props.theme.palette.primary.text};
`;

const Subtitle = styled.section`
  text-align: center;
  color: ${props => props.theme.palette.primary.text};
`;

const Error = () => {
  return (
    <Layout>
      <Wrapper>
        <Title>404</Title>
        <Subtitle>Page not found</Subtitle>
      </Wrapper>
    </Layout>
  );
};

export default Error;
